<template>
  <div>

  </div>
</template>

<script>
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';

  export default {
    name: 'Main-Default',

    mounted() {

      var Ubicacion = window.location.href;
      console.log(Ubicacion);
    //  Ubicacion = Ubicacion.replace("/?", "#/?");
      Ubicacion = Ubicacion.replace("index.html", "");
      var currentUrl = window.location.pathname;
      console.log(currentUrl);
      if (currentUrl !== undefined && currentUrl !== "/") {
        console.log(currentUrl);

        var Recortar = Ubicacion.indexOf("#");
        var NuevaUbicacion = Ubicacion.substring(0, Recortar);
        NuevaUbicacion = NuevaUbicacion.replace(currentUrl, "/#" + currentUrl);
        //NuevaUbicacion = NuevaUbicacion.replace("http://", "https://");
        console.log(NuevaUbicacion);
        if (this.RedirigeHttps(NuevaUbicacion, true))
          return;
      }
      else {
        if (this.RedirigeHttps(Ubicacion))
          return;
      }
      //Si no se ha redirigido ahora debe mandar al home correcto


      /*
      var SU = SuscripcionesUsuarios.getSuscripcionUsuario();
     if (SU == null) {
        console.debug("Redirigiendo a SP")
        //Si no hay suscripción entonces debe logearse para obtener una
        this.$router.replace("/suscripciones/pregunta");
        return;
      }*/

      this.$router.replace("/main/home");
      return;


    },
    methods: {


      RedirigeHttps(Ubicacion, Forzar) {
        var NUrl = `${Ubicacion}`;
        if (NUrl.indexOf("localhost") < 0 && NUrl.indexOf("10.") < 0) {
          NUrl = NUrl.replace("http://", "https://");

        }
        if (NUrl != Ubicacion || (Forzar != null && Forzar != false)) {
          window.location.href = NUrl;
          return true;
        }
        return false;
      },
    }
  };
</script>

<style scoped>
</style>
